@charset "utf-8";
@import "../core/_mixin.scss";
@import "../core/_config.scss";

.footer{
  border-top:1px solid $c1;
  position: relative;
  margin-top: 150px;
  /*.running{
    width: 40px;
    position: absolute;
    top: -76px;
    left: 5%;
  }*/
  .running{
    width: 200px;
    position: absolute;
    top: -100px;
    left: 0%;
    @include media(767px) {
      left: -9%;
    }
  }
  .logo{
    width: 70%;
    max-width: 300px;
    margin: 20px auto 40px;
    @include media(767px) {
      margin: 20px auto 20px;
    }
  }
  .sns{
    margin: 0 auto 20px;
    width: 150px;
    @include media(767px) {
      margin: 0 auto;
    }
    li{
      float: left;
      margin-right: 20px;
      font-size: 40px;
      &:last-of-type{
        margin-right: 0px;
      }
      a{
        transition: 0.2s;
      }
      &:hover{
        a{
          color:$c1;
        }

      }
    }
  }
  .subnav{
    text-align: center;
    width: 700px;
    margin: 0 auto;
    @include media(767px) {
      display: none;
    }
    li{
      float:left;
      border-left: 1px solid $base_font_color;
      padding:0px 20px;
      box-sizing: border-box;
      line-height: 1;
      font-size: 14px;
      
      &:last-of-type{
        border-right: 1px solid $base_font_color;
      }
      a{
        transition: 0.2s;
      }
      &:hover{
        a{
          color:$c1;
        }
       
      }
    }
  }
  .copy{
    font-size:10px;
    text-align: center;
    padding:20px 0;
    background:#eeeeee;
  }
}

.ebi-1gou{
  animation: ebi-1gou-move 10s ease-in-out 0s infinite normal;
}
@keyframes ebi-1gou-move{
  0%{
    transform: translateX(-5vw);
  }
  100%{
    transform: translateX(102vw);
  }
}