@charset "utf-8";
@import "../core/_mixin.scss";
@import "../core/_config.scss";

.pagination{
  text-align: center;
  margin-bottom:15px;
  span,
  a{
    display: inline-block;
  }
}
a.page-numbers,
.pagination .current{
  padding:5px 8px;
  margin:0 2px;
  text-decoration: none;
}
.pagination .current{
  opacity: 0.8;
}
