@charset "utf-8";
@import "_mixin.scss";

$c1: #ff7060;
$c2: #df98ab;
$c3: #f6de17;
$c4: #d70051;
$c5: #56c3f1;
$c6: #ffe446;
$c7: #e4007f;

$base_font_color: #1b1b1b;
$link_color: #ff7060;

$google-color: #C53727;
$facebook-color: #3B5998;
$twitter-color: #00aced;
$line-color: #5ae628;
$instagram-color: #3f729b;
$tumblr-color: #34526f;