@charset "utf-8";
@import "../core/_mixin.scss";
@import "../core/_config.scss";

.header_pc{
  position: fixed;
  top:0;
  left:0;
  background: #fff;
  z-index: 1000;
  width: 100%;
  border-bottom: 1px solid $c1;
  @include media(767px) {
    display:none;
  }
  .head01{
    padding:10px 0;
    border-bottom: 1px solid $c1;
  }
  .sitename{
    padding-top:8px;
    font-size: 12px;
    width: max-content;
  }
  .sns{
    width: 80px;
    li{
      float: left;
      margin-right: 10px;
      font-size: 20px;
      &:last-of-type{
        margin-right: 0px;
      }
      a{
        transition: 0.2s;
      }
      &:hover{
        a{
          color:$c1;
        }

      }
    }
  }
  .head02{
    .logo{
      width: 25%;
      padding:30px 0px 0;
      border-right: 1px solid $c1; 
      box-sizing: border-box;
      img{
        width: 80%;
        max-width: 260px;
        margin: 0 0 0 0;
      }
      &:hover{
        img{
          opacity: 0.8;
        }
      }
    }
    .gnav{
      width: 75%;
      li{
        width: 16.6%;
        padding:20px 10px;
        box-sizing: border-box;
        border-right: 1px solid $c1;
        transition: 0.2s;
        img{
          height: 50px;
          width: auto;
          margin: 0 auto;
          @include media(1039px) {
            height: 36px;
          }
        }
       
        &:hover{
          background: #ffd5d0;
          img{
            opacity: 0.8;
          }
          
        }
      }
    }
  }
}

.spnav{
  display: none;
}


/*-----------------------------------------------
--nav
-----------------------------------------------*/

@include media(767px) {
  .spnav{
    display: block;
  }
  /*   ボタンタグ設定   */
  .btn_menu{
    display: block;
    background: none;
    border: none;
    padding: 0;
    width: 42px;
    color: $c1;
    letter-spacing: 0.1em;
    cursor: pointer;
    position: fixed;
    top: 7px;
    right: 20px;
    z-index: 1001;
    text-align: center;
    outline: none;
    font-size: 10px;
  }
  /*   ハンバーガーボタン3本線   */
  .btn_menu span.bar {
    display: block;
    height: 2px;
    background-color:$c1;
    margin: 10px 0;
    transition: all 0.2s;
    transform-origin: 0 0;
  }
  .btn_menu .close {
    letter-spacing: 0.08em;
    display: none;
  }
  .btn_menu .menu {
    display: block;
  }
  /*  メニューアクティブ時  */
  .btn_menu.active span.bar {
    width: 49px;
  }
  .btn_menu.active .bar1 {
    transform: rotate(30deg);
  }
  .btn_menu.active .bar2 {
    opacity: 0;
  }
  .btn_menu.active .bar3 {
    transform: rotate(-30deg);
  }
  .btn_menu.active .menu {
    display: none;
  }
  .btn_menu.active .close {
    display: block;
  }
  /*  メニュー  */
  .head_nav {
    width: 90%;
    height: 100%;
    transition: all 0.2s;
    transform: translate(100%);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    background:#ffffff;
  }
  /*  メニューアクティブ時  */
  .head_nav.open {
    transform: translate(0);
  }
  /*　以下、個別にレイアウト調整　*/
  .nav{
    background:#ffffff;
    ul{
      padding:100px 20px;
      box-sizing: border-box;
      text-align: center;
      background:#ffffff;
    }
    li{
      width: 50%;
      padding:20px;
      box-sizing: border-box;
      border-right: 1px solid $c1;
      border-bottom: 1px solid $c1;
      &:nth-of-type(odd){
        border-left: 1px solid $c1;
      }
      &:nth-of-type(1),
      &:nth-of-type(2){
        border-top: 1px solid $c1;
      }
      img{
        height: 50px;
        width: auto;
      }
    }
    .panda{
      margin: 60px auto 0;
    }
  }
  .spnav{
    padding:15px 10px 20px;
    box-sizing: border-box;
    background: #fff;
    border-bottom: 1px solid $c1;
    position: fixed;
    top:0;
    left: 0;
    z-index: 1000;
    width: 100%;
    .sitename{
      font-size: 10px;
      margin-bottom: 5px;
    }
    .logo{
      width: 200px;
    }
  }
}