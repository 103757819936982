@charset "utf-8";
@import "../core/_mixin.scss";
@import "../core/_config.scss";

/*--------
--common parts
-----------------------------------------------*/
.contawrap{
  margin-top: 111px;
  @include media(1039px) {
    margin-top: 98px;
  }
  @include media(767px) {
    margin-top: 78px;
  }
}
.bnrarea{
  width: 90%;
  max-width:800px;
  margin: 0 auto;
  &:hover{
    opacity: 0.8;
  }
}
a.btn01{
  padding:20px 40px;
  text-align: center;
  display: inline-block;
  color:#fff;
  background:$c1;
  transition: 0.2s;
  box-sizing: border-box;
  border: 1px solid #fff;
  text-decoration: none;
  &:hover{
    background:#fff;
    border: 1px solid $c1;
    box-sizing: border-box;
    color:$c1;
  }
}
.btnwrap{
  margin: 40px auto 0;
  text-align: center;
  width: 100%;
}
.ttl_01{
  font-size: 30px;
  letter-spacing: 2px;
  color:$c1;
  &:after{
    width:60px;
    height: 1px;
    background:$c1;
    content:"";
    display: block;
    margin: 40px 0;
    @include media(767px) {
      margin: 20px 0;
    }
  }
  @include media(767px) {
    font-size: 20px;
    line-height: 1;
  }
}
.ttl_02{
  font-size: 30px;
  letter-spacing: 2px;
  color:$c1;
  text-align: center;
  line-height: 1;
  margin: 0 auto;
  width: 100%;
  &:after{
    width:30px;
    height: 1px;
    background:$c1;
    content:"";
    display: block;
    margin: 30px auto;
    @include media(767px) {
      margin: 20px auto;
    }
  }
  @include media(767px) {
    font-size: 20px;
  }
}
.mv_p{
  .catch{
    width:40%;
    background:$c1;
    padding:80px 0 100px 5%;
    box-sizing: border-box;
    @include media(1039px) {
      width:50%;
    }
  }
  .eng_ttl{
    font-size: 75px;
    font-family: lato,sans-serif;
    font-style: italic;
    letter-spacing: 2px;
    color:#fff;
    &:after{
      width:130px;
      height: 5px;
      background:#fff;
      content:"";
      display: block;
      margin: 20px 0;
    }
    @include media(1039px) {
      font-size: 7vw;
      line-height: 1.2;
    }
  }
  .ja_ttl{
    font-size: 30px;
    color:#fff;
    @include media(1039px) {
      font-size: 4vw;
    }
  }
  .mv_bg{
    width:60%;
    background:url(../img/mv_works.jpg) no-repeat left center;
    background-size: cover;
    @include media(1039px) {
      width:50%;
      background:url(../img/mv_works.jpg) no-repeat left 10% center;
      background-size: cover;
    }
    @include media(767px) {
      background:url(../img/mv_works.jpg) no-repeat center center;
      background-size: cover;
    }
  }
  
}
.sc01{
  .ttl{
    color:$c2;
    margin:10% 0 20px -10px;
    font-size:24px;
    line-height: 1.2;
    @include media(767px) {
      margin:40px auto 20px;
      font-size:20px;
    }
  }
  .c_l{
    width:40%;
    @include media(767px) {
      width: 90%;
    }
  }
  .c_r{
    width: 55%;
    @include media(767px) {
      width: 100%;
    }
  }
  .caution{
    width: 90%;
    max-width: 1040px;
    margin: 0 auto;
    @include media(767px) {
      width: 100%;
    }
    .lead{
      color:$c1;
      text-align: center;
      margin-bottom: 20px;
      font-size: 20px;
    }
  }
}
.sc02{
  background:url(../img/bg01.png) no-repeat right top 8%;
  background-size: 100px;
  @include media(767px) {
    background:url(../img/bg01.png) no-repeat right top 2%;
    background-size: 60px;
  }
  .lead{
    width: 100%;
    padding-right:150px;
    box-sizing: border-box;
    @include media(767px) {
      padding-right:0px;
    }
  }
}
.sc03{
  background:url(../img/bg02.png) no-repeat right top 4%;
  background-size: 200px;
  @include media(767px) {
    background:url(../img/bg02.png) no-repeat right top 2%;
    background-size: 100px;
  }
  .lead{
    width: 100%;
    padding-right:250px;
    box-sizing: border-box;
    @include media(767px) {
      padding-right:0px;
    }
  }
}
.sc04{
  background:url(../img/bg03.png) no-repeat right top 7%;
  background-size: 200px;
  @include media(767px) {
    background:url(../img/bg03.png) no-repeat right top 3%;
    background-size: 100px;
  }
  .lead{
    width: 100%;
    padding-right:250px;
    box-sizing: border-box;
    @include media(767px) {
      padding-right:0px;
    }
  }
}
.price_list{
  margin-top: 40px;
  li{
    border:1px solid $c1;
    margin-bottom: 40px;
    background:#fff;
    &:last-of-type{
      margin-bottom: 0;
    }
    .c_l{
      width:30%;
      background:$c1;
      color:#fff;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap:wrap;
      font-size:24px;
      letter-spacing: 2px;
      text-align: center;
      @include media(1039px) {
        width:100%;
        padding:20px 0;
      }
      @include media(767px) {
        font-size:18px;
        padding:15px 0;
      }
    }
    .c_r{
      width:70%;
      padding:40px;
      box-sizing: border-box;
      @include media(1039px) {
        width:100%;
      }
      @include media(767px) {
        padding:20px 10px;
      }
    }
    .ttl_min{
      font-size: 12px;
      display: block;
      margin-top: 20px;
    }
  }
}
.sc_exs {
  border:1px dashed $c1;
  .ttl{
    margin: -87px auto 40px;
    background:#fff;
    padding:10px 60px;
    box-sizing: border-box;
    font-size:24px;
    color:$c1;
    text-align: center;
    width:max-content;
    @include media(1039px) {
      margin:-69px auto 16px;
    }
    @include media(767px) {
      padding:10px 20px;
      font-size:18px;
      margin:-62px auto 10px;
    }
  }
  .textbox a{
    text-decoration: underline;
    color:$c2;
  }
}

.sponserwrap{
  .sc01{
    .list{
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      li{
        width: 32%;
        font-size:16px;
        text-align: center;
        img{
          max-width: 250px;
          margin-bottom: 20px;
        }
        @include media(767px) {
          width: 90%;
          margin-bottom: 40px;
          &:last-of-type{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .mv_bg{
    width:60%;
    background:url(../img/mv_spo.jpg) no-repeat left top;
    background-size: cover;
    @include media(1039px) {
      width:50%;
      background:url(../img/mv_spo.jpg) no-repeat left 10% top;
      background-size: cover;
    }
    @include media(767px) {
      background:url(../img/mv_spo.jpg) no-repeat center top;
      background-size: cover;
    }
  }
}

.contactwrap{
  .mv_bg{
    width:60%;
    background:url(../img/mv_contact.jpg) no-repeat top center;
    background-size: cover;
    @include media(1039px) {
      width:50%;
      background:url(../img/mv_contact.jpg) no-repeat top center;
      background-size: cover;
    }
    @include media(767px) {
      background:url(../img/mv_contact.jpg) no-repeat top center;
      background-size: cover;
    }
  }
}
.profilewrap{
  .mv_bg{
    width:60%;
    background:url(../img/mv_profile.jpg) no-repeat top center;
    background-size: cover;
    @include media(1039px) {
      width:50%;
      background:url(../img/mv_profile.jpg) no-repeat top center;
      background-size: cover;
    }
    @include media(767px) {
      background:url(../img/mv_profile.jpg) no-repeat top center;
      background-size: cover;
    }
  }
  .flex_rv{
    align-items: center;
  }
  .sc01{
    .ttl{
      span{
        font-size:12px;
        padding-left: 15px;
        box-sizing: border-box;
      }
      @include media(1039px) {
        margin:40px auto 20px;
      }
    }
    .c_l{
      width:40%;
      @include media(1039px) {
        width: 90%;
        max-width: 450px;
        margin: 0 auto;
      }
    }
    .c_r{
      width: 55%;
      @include media(1039px) {
        width: 100%;
      }
    }
  }
  .sc02{
    background: none;
    .c_l{
      width: 48%;
      background: url(../img/pro02.jpg) no-repeat right top;
      background-size: contain;
      text-shadow: 0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff;
      @include media(1039px) {
        width: 100%;
        margin-bottom: 40px;
      }
    }
    .c_r{
      width: 48%;
      background: url(../img/pro03.jpg) no-repeat right top;
      background-size: contain;
      text-shadow: 0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff;
      @include media(1039px) {
        width: 100%;
      }
    }
  }
  .sc03{
    background: none;
    .c_l{
      width: 48%;
      @include media(1039px) {
        width: 100%;
        margin-bottom: 40px;
      }
    }
    .c_r{
      width: 48%;
      @include media(1039px) {
        width: 100%;
      }
    }
  }
  .sc04{
    background: none;
    figure{
      width: 90%;
      max-width: 800px;
      margin: 0 auto;
    }
  }
  .sc05{
    background: url(../img/pro04.jpg) no-repeat right top 100px;
    background-size: 260px;
    text-shadow: 0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff;
    @include media(1039px) {
      background: url(../img/pro04.jpg) no-repeat right top 100px;
      background-size: 200px;
    }
    @include media(767px) {
      background: none;
    }
  }
}
.topwrap{
  @include media(767px) {
    .txt-c{
      text-align: left;
    }
  }
  .sc01{
    @include media(767px) {
      .c_l{
        max-width: 300px;
      }
    }
    
  }
  .sc02{
    background: none;
    .article{
      width:31%;
      margin-bottom: 40px;
      @include media(1039px) {
        width:48%;
        margin-bottom: 40px;
      }
      @include media(600px) {
        width:100%;
        margin-bottom: 40px;
      }
    }
    .blogdate{
      font-size:12px;
      color:#666;
      margin: 15px 0 10px;
    }
    .blogtitle{
      font-size: 18px;
      margin-bottom: 10px;
      display: inline-block;
      color:$c1;
    }
    .blog-text{
      font-size: 14px;
      line-height: 2;
    }
  }
  .sc03{
    background: none;
    .date{
      font-size:12px;
      color:#666;
      margin: 0 0 10px;
    }
    .blogtitle{
      font-size: 18px;
      margin-bottom: 10px;
      display: inline-block;
      color:$c1;
      text-decoration: underline;
      a{
        color:$c1;
      }
    }
    .blogtext{
      font-size: 14px;
      line-height: 2;
      border-bottom: 1px dotted $c1;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }
  .sc04{
    background: none;
    

    .list{
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      li{
        width: 32%;
        font-size:16px;
        text-align: center;
        img{
          max-width: 250px;
          margin-bottom: 20px;
        }
        @include media(767px) {
          width: 90%;
          margin-bottom: 40px;
          &:last-of-type{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .sc05{
    .worklist{
      max-width: 1000px;
      margin: 0 auto;
      li{
        position: relative;
        margin-bottom: 60px;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
      .c_l{
        width: 50%;
        @include media(767px) {
          width: 100%;
          margin-bottom: 25px;
        }
      }
      .c_r{
        width: 45%;
        @include media(767px) {
          width: 100%;
        }
      }
      .num{
        font-size: 75px;
        font-family: lato,sans-serif;
        font-style: italic;
        color:$c1;
        position: absolute;
        top:-60px;
        left: 5px;
        z-index: 2;
        font-weight: bold;
      }
      .ttl{
        color:$c1;
        font-size: 20px;
        border-left: 4px sollid $c1;
        margin-bottom: 20px;
      }
    }
  }
  /*.top_bgswipe{
    width: 80%;
    background-position:center left;
    margin-left: 20%;
  }
  .top_catch{
    width: 55%;
    height: 70vh;
    margin-left: -25%;
    min-width: 500px;
  }*/
  .mv_top{
    position: relative;
  }
  .top_catch{
    position: absolute;
    top: 8%;
    left: 0px;
    z-index: 50;
    width: 42%;
    min-width: 500px;
    @include media(1039px) {
      top: 3%;
    }
    @include media(767px) {
      width: 80%;
      min-width: 280px;
      top: 12%;
    }
  }
  .slide_top_wrap{

    width: 80%;
    margin-left: 20%;
  }
}

/*--------
--blog
-----------------------------------------------*/

.col_left{
  width: 70%;
  @include media(767px) {
    width: 100%;
    margin-bottom: 60px;
  }
}

.col_right{
  width: 25%;
  @include media(767px) {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
}

.blogwrap{
  .mv_bg{
    width:60%;
    background:url(../img/mv_blog.jpg) no-repeat top center;
    background-size: cover;
    @include media(1039px) {
      width:50%;
      background:url(../img/mv_blog.jpg) no-repeat top center;
      background-size: cover;
    }
    @include media(767px) {
      background:url(../img/mv_blog.jpg) no-repeat top center;
      background-size: cover;
    }
  }
}
.related-article li{
  @include media(767px) {
    margin-bottom: 40px;
  }
}
.related-article-title{
  font-size: 16px;
  color:$c1;
  margin-bottom: 20px;
  letter-spacing: 2px;
}
.article_blog_rela{
  .eye-catch{
    width: 30%;
    @include media(767px) {
      width: 90%;
      margin:  0 auto 20px !important;
    }
  }
  .related-article-text{
    width: 65%;
    @include media(767px) {
      width: 100%;
    }
    .date{
      font-size: 12px;
      text-align: right;
      display: inline-block;
    }
    .ttl{
      font-size: 16px;
    }
  }
}
.article_blog{
  margin-bottom: 40px;
  padding: 0 0 40px;
  border-bottom: 1px dashed #eee;
  
  .eye-catch{
    width: 30%;
    @include media(767px) {
      width: 90%;
     margin:  0 auto 20px;
    }
  }
  
  .article-right{
    width: 65%;
    @include media(767px) {
      width: 100%;
    }
    .cate-name{
      border:1px solid #d6d6d6;
      padding:5px 10px 5px;
      box-sizing: border-box;
      display: inline-block;
      font-size: 12px;
      margin-right: 10px;
      a{
        text-decoration: none;
        color:$base_font_color;
      }
    }
    .date{
      font-size: 12px;
      text-align: right;
      display: inline-block;
    }
    .title{
      font-size: 18px;
      margin:20px 0;
      @include media(767px) {
        font-size: 16px;
      }
      a{
        text-decoration: none;
        transition: 0.8s;
        &:hover{
          color:$c1;
        }
      }
    }
    .text{
      font-size: 14px;
    }
  }
}
.article_info{
  margin-bottom: 40px;
  padding: 0 0 40px;
  border-bottom: 1px dashed #eee;
  .cate-name{
    border:1px solid #d6d6d6;
    padding:5px 10px 5px;
    box-sizing: border-box;
    display: inline-block;
    font-size: 12px;
    margin-right: 10px;
    a{
      text-decoration: none;
      color:$base_font_color;
    }
  }
  .cate-tag{
    padding:5px 10px 5px;
    box-sizing: border-box;
    display: inline-block;
    font-size: 12px;
    margin-right: 10px;
    a{
      text-decoration: none;
      color:$base_font_color;
    }
  }
  .date{
    font-size: 12px;
    text-align: right;
    display: inline-block;
  }
  .title{
    font-size: 18px;
    margin:20px 0 20px;
    border-bottom: 1px solid $c1;
    @include media(767px) {
      font-size: 16px;
    }
    a{
      text-decoration: none;
      transition: 0.8s;
      &:hover{
        color:$c1;
      }
    }
  }
  .more{
    border:1px solid $c1;
    margin: 10px 0 0;
    text-align: center;
    padding:3px 15px;
    box-sizing: border-box;
    display: inline-block;
    a{
      color:$c1;
      font-size: 12px;
    }
  }
  .text{
    font-size: 14px;
  }
  .blog-title{
    font-size: 18px;
    margin:10px 0 20px;
    border-bottom: 1px solid $c1;
    padding-bottom: 10px;
    @include media(767px) {
      font-size: 16px;
    }
  }
}
.sidebar-block{
  margin-bottom: 50px;
  .ttl{
    font-size: 16px;
    color:$c1;
    margin-bottom: 20px;
    letter-spacing: 2px;
    padding: 0 0 5px;
    border-bottom: 1px dashed #ddd;
  }
  .cat-item {
    margin-bottom: 10px;
    font-size: 14px;
    transition: 1s;
    a:hover{
      color:$c1;
    }
  }
  &.recommend{
    li{
      margin-bottom: 20px;
    }
    .eye-catch{
      width: 30%;
      margin: 0 auto 20px !important;
    }
    .sidebar-text{
      width: 65%;
      font-size: 12px;
    }
  }
  &.ranking{
    li{
      margin-bottom: 20px;
    }
    .eye-catch{
      width: 30%;
      margin: 0 auto 20px !important;
    }
    .sidebar-text{
      width: 65%;
      font-size: 12px;
    }
  }

}
.pagenavi_o{
  text-align: center;
  margin: 0 auto 40px;
  .wp-pagenavi a:hover, .wp-pagenavi span.current {
    border-color: $c1;
    color:$c1;
    font-weight: normal;
  }
}

.infowrap{
  .mv_bg{
    width:60%;
    background:url(../img/mv_info.jpg) no-repeat center center;
    background-size: cover;
    @include media(1039px) {
      width:50%;
      background:url(../img/mv_info.jpg) no-repeat center center;
      background-size: cover;
    }
    @include media(767px) {
      background:url(../img/mv_info.jpg) no-repeat center center;
      background-size: cover;
    }
  }
  .text{
    img{
      width: 90%;
      margin: 0 auto 40px;
      max-width: 800px;
    }
    h2{
      font-weight: bold;
      color:$c1;
      font-size: 18px;
      margin-top: 40px;
      margin-bottom: 20px;
      border-bottom: 1px dashed $c1;
    }
    h3{
      font-size: 16px;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    h4{
      color: $c1;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    a{
      text-decoration: underline;
    }
  }
  .pb-20 {
    padding-bottom: 20px;
    line-height: normal;
    img{
      width: 90%;
      margin: 0 auto 0px;
      max-width: 800px;
    }
  }
  font{
    text-align: center;
  }
  .eye-catch{
    max-width: 800px;
    margin: 0 auto 40px;
  }
}

div.wp_social_bookmarking_light{
  margin-top: 40px !important;
}
.info-left-wrapper{
  a{
    font-size: 14px;
    color:$c1;
  }
  .before{
    display: inline-block;
    padding-right: 10px;
  }
  .list{
    display: inline-block;
    padding:0 10px;
  }
  .next{
    display: inline-block;
  }
}