@charset "UTF-8";
/*-----------------------------------------------
--メインカラム
-----------------------------------------------*/
.lay_l {
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .lay_l {
    width: 95%;
  }
}

.lay_m {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .lay_m {
    width: 95%;
  }
}

.lay_s {
  max-width: 1040px;
  width: 90%;
  margin: 0 auto;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .lay_s {
    width: 95%;
  }
}

/*-----------------------------------------------
--コンテンツボックス 都度調整OK
-----------------------------------------------*/
.pt_xl {
  padding-top: 100px;
}

@media screen and (max-width: 767px) {
  .pt_xl {
    padding-top: 80px;
  }
}

.pt_l {
  padding-top: 60px;
}

@media screen and (max-width: 767px) {
  .pt_l {
    padding-top: 40px;
  }
}

.pt_m {
  padding-top: 60px;
}

@media screen and (max-width: 767px) {
  .pt_m {
    padding-top: 40px;
  }
}

.pt_s {
  padding-top: 40px;
}

@media screen and (max-width: 767px) {
  .pt_s {
    padding-top: 20px;
  }
}

.pb_xl {
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .pb_xl {
    padding-bottom: 80px;
  }
}

.pb_l {
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .pb_l {
    padding-bottom: 80px;
  }
}

.pb_m {
  padding-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .pb_m {
    padding-bottom: 40px;
  }
}

.pb_s {
  padding-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .pb_s {
    padding-bottom: 20px;
  }
}

.ptb_xl {
  padding: 100px 0;
}

@media screen and (max-width: 767px) {
  .ptb_xl {
    padding: 80px 0;
  }
}

.ptb_l {
  padding: 60px 0;
}

@media screen and (max-width: 767px) {
  .ptb_l {
    padding: 40px 0;
  }
}

.ptb_m {
  padding: 40px 0;
}

@media screen and (max-width: 767px) {
  .ptb_m {
    padding: 20px 0;
  }
}

.ptb_s {
  padding: 40px 0;
}

@media screen and (max-width: 767px) {
  .ptb_s {
    padding: 20px 0;
  }
}

.pa_xl {
  padding: 100px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .pa_xl {
    padding: 80px;
  }
}

.pa_l {
  padding: 60px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .pa_l {
    padding: 40px 10px;
  }
}

.pa_m {
  padding: 60px;
  box-sizing: border-box;
}

@media screen and (max-width: 1039px) {
  .pa_m {
    padding: 40px;
  }
}

@media screen and (max-width: 767px) {
  .pa_m {
    padding: 40px 10px;
  }
}

.pa_s {
  padding: 40px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .pa_s {
    padding: 20px 10px;
  }
}

.mt_xl {
  margin-top: 100px;
}

@media screen and (max-width: 767px) {
  .mt_xl {
    margin-top: 80px;
  }
}

.mt_l {
  margin-top: 140px;
}

@media screen and (max-width: 767px) {
  .mt_l {
    margin-top: 80px;
  }
}

.mt_m {
  margin-top: 60px;
}

@media screen and (max-width: 767px) {
  .mt_m {
    margin-top: 40px;
  }
}

.mt_s {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .mt_s {
    margin-top: 20px;
  }
}

.mb_xl {
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .mb_xl {
    margin-bottom: 80px;
  }
}

.mb_l {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .mb_l {
    margin-bottom: 40px;
  }
}

.mb_m {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .mb_m {
    margin-bottom: 40px;
  }
}

.mb_s {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .mb_s {
    margin-bottom: 20px;
  }
}

.ma_xl {
  margin: 100px auto;
}

@media screen and (max-width: 767px) {
  .ma_xl {
    margin: 80px auto;
  }
}

.ma_l {
  margin: 80px auto;
}

@media screen and (max-width: 767px) {
  .ma_l {
    margin: 60px auto;
  }
}

.ma_m {
  margin: 60px auto;
}

@media screen and (max-width: 767px) {
  .ma_m {
    margin: 40px auto;
  }
}

.ma_s {
  margin: 40px auto;
}

@media screen and (max-width: 767px) {
  .ma_s {
    margin: 20px auto;
  }
}

/*-----------------------------------------------
--フォントサイズ 都度調整OK
-----------------------------------------------*/
.fz_l {
  font-size: 24px;
}

@media screen and (max-width: 767px) {
  .fz_l {
    font-size: 20px;
  }
}

.fz_m {
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .fz_m {
    font-size: 18px;
  }
}

.fz_s {
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .fz_s {
    font-size: 10px;
  }
}

/*-----------------------------------------------
--段落用（レスポンシブ）
-----------------------------------------------*/
.pc {
  display: block;
}

@media screen and (max-width: 767px) {
  .pc {
    display: none;
  }
}

.tab {
  display: none;
}

@media screen and (max-width: 1040px) {
  .tab {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .tab {
    display: none;
  }
}

.sp {
  display: none;
}

@media screen and (max-width: 767px) {
  .sp {
    display: block;
  }
}

.spmin {
  display: none;
}

@media screen and (max-width: 450px) {
  .spmin {
    display: block;
  }
}

/*-----------------------------------------------
--ホバーアクション（透過）
-----------------------------------------------*/
.hv {
  transition: 0.2s;
}

.hv:hover {
  opacity: 0.8;
}

/*-----------------------------------------------
--画面ローディング
-----------------------------------------------*/
body {
  animation: fadeIn 2s ease 0s 1 normal;
  -webkit-animation: fadeIn 2s ease 0s 1 normal;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*-----------------------------------------------
--フロート 
-----------------------------------------------*/
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  visibility: hidden;
}

/*-----------------------------------------------
--grid
-----------------------------------------------*/
.grid_c3 {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
}

.grid_autofit {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

/*-----------------------------------------------
--Flex
-----------------------------------------------*/
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

@media screen and (max-width: 767px) {
  .flex {
    justify-content: space-around;
  }
}

.flex_rv {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

@media screen and (max-width: 767px) {
  .flex_rv {
    justify-content: space-around;
  }
}

.flex_ct {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .flex_ct {
    justify-content: space-around;
  }
}

/*-----------------------------------------------
--Fonts
-----------------------------------------------*/
body {
  line-height: 1.6;
  font-size: 16px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  /*折り返しを綺麗にする*/
  font-weight: normal;
  font-family: Avenir, Verdana, Arial, 'Noto Sans JP', "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Roboto, Meiryo, sans-serif;
  text-rendering: optimizeLegibility;
  /*アンチエイリアスなどフォント見やすくする*/
  color: #1b1b1b;
  text-align: justify;
  /*均等に配置する*/
}

@media screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }
}

/*----游ゴシック__fontfamily---*/
/*body {
font-family:Avenir, Verdana, Arial, "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Roboto, Meiryo, sans-serif;
font-weight: 500;
}*/
a, a:link, a:active, a:visited {
  text-decoration: none;
  outline: none;
  color: #1b1b1b;
}

a:hover, a:active {
  outline: 0;
}

/*-----------------------------------------------
--fontfamily
-----------------------------------------------*/
.serif {
  font-family: 'Times New Roman', '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', '小塚明朝 ProN', 'メイリオ', Meiryo, serif;
}

/*-----------------------------------------------
--パーツ各種
-----------------------------------------------*/
.textbox {
  line-height: 2.2;
}

.textbox p {
  margin-bottom: 25px;
}

.textbox p:last-of-type {
  margin-bottom: 0;
}

.textbox a {
  text-decoration: underline;
  display: inline-block;
  transition: 0.2s;
}

.textbox a:hover {
  opacity: 0.8;
  color: #ff7060;
}

.textbox strong {
  font-weight: bold;
}

.textbox strong.deco {
  color: #ff7060;
  background: linear-gradient(transparent 60%, #ffff66 60%);
}

.tri {
  width: 0;
  height: 0;
  border: 80px solid transparent;
  border-top: 60px solid #fe6601;
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .tri {
    border: 60px solid transparent;
    border-top: 40px solid #fe6601;
  }
}

.bold {
  font-weight: bold;
}

.hl01 {
  color: #ff7060;
}

.txt-c {
  text-align: center;
}

.txt-r {
  text-align: right;
}

.txt-l {
  text-align: left;
}

.grad-btn {
  background: #ff7060;
  padding: 10px 20px;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  font-size: 14px;
  transition: 0.2s;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.grad-btn:hover {
  opacity: 0.8;
}

.grad-btn:before {
  content: "続きを読む";
}

.grad-item {
  position: relative;
  overflow: hidden;
  height: 0px;
  /*隠した状態の高さ*/
}

.grad-item::before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  /*グラデーションで隠す高さ*/
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.9) 50%, #fff 100%);
  background: linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.9) 50%, #fff 100%);
  content: "";
}

.grad-trigger {
  display: none;
  /*チェックボックスは常に非表示*/
}

.grad-trigger:checked ~ .grad-btn::before {
  content: "閉じる";
}

.grad-trigger:checked ~ .grad-item {
  height: auto;
  /*チェックされていたら、高さを戻す*/
}

.grad-trigger:checked ~ .grad-item::before {
  display: none;
  /*チェックされていたら、grad-itemのbeforeを非表示にする*/
}

/*-----------------------------------------------
--Animation
-----------------------------------------------*/
.ani01_wrap {
  width: 0;
  overflow: hidden;
  transition: 1000ms linear;
}

.ani01_wrap.is-show {
  width: 320px;
}

/*-----------------------------------------------
--header
-----------------------------------------------*/
.header_pc {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 1000;
  width: 100%;
  border-bottom: 1px solid #ff7060;
}

@media screen and (max-width: 767px) {
  .header_pc {
    display: none;
  }
}

.header_pc .head01 {
  padding: 10px 0;
  border-bottom: 1px solid #ff7060;
}

.header_pc .sitename {
  padding-top: 8px;
  font-size: 12px;
  width: max-content;
}

.header_pc .sns {
  width: 80px;
}

.header_pc .sns li {
  float: left;
  margin-right: 10px;
  font-size: 20px;
}

.header_pc .sns li:last-of-type {
  margin-right: 0px;
}

.header_pc .sns li a {
  transition: 0.2s;
}

.header_pc .sns li:hover a {
  color: #ff7060;
}

.header_pc .head02 .logo {
  width: 25%;
  padding: 30px 0px 0;
  border-right: 1px solid #ff7060;
  box-sizing: border-box;
}

.header_pc .head02 .logo img {
  width: 80%;
  max-width: 260px;
  margin: 0 0 0 0;
}

.header_pc .head02 .logo:hover img {
  opacity: 0.8;
}

.header_pc .head02 .gnav {
  width: 75%;
}

.header_pc .head02 .gnav li {
  width: 16.6%;
  padding: 20px 10px;
  box-sizing: border-box;
  border-right: 1px solid #ff7060;
  transition: 0.2s;
}

.header_pc .head02 .gnav li img {
  height: 50px;
  width: auto;
  margin: 0 auto;
}

@media screen and (max-width: 1039px) {
  .header_pc .head02 .gnav li img {
    height: 36px;
  }
}

.header_pc .head02 .gnav li:hover {
  background: #ffd5d0;
}

.header_pc .head02 .gnav li:hover img {
  opacity: 0.8;
}

.spnav {
  display: none;
}

/*-----------------------------------------------
--nav
-----------------------------------------------*/
@media screen and (max-width: 767px) {
  .spnav {
    display: block;
  }
  /*   ボタンタグ設定   */
  .btn_menu {
    display: block;
    background: none;
    border: none;
    padding: 0;
    width: 42px;
    color: #ff7060;
    letter-spacing: 0.1em;
    cursor: pointer;
    position: fixed;
    top: 7px;
    right: 20px;
    z-index: 1001;
    text-align: center;
    outline: none;
    font-size: 10px;
  }
  /*   ハンバーガーボタン3本線   */
  .btn_menu span.bar {
    display: block;
    height: 2px;
    background-color: #ff7060;
    margin: 10px 0;
    transition: all 0.2s;
    transform-origin: 0 0;
  }
  .btn_menu .close {
    letter-spacing: 0.08em;
    display: none;
  }
  .btn_menu .menu {
    display: block;
  }
  /*  メニューアクティブ時  */
  .btn_menu.active span.bar {
    width: 49px;
  }
  .btn_menu.active .bar1 {
    transform: rotate(30deg);
  }
  .btn_menu.active .bar2 {
    opacity: 0;
  }
  .btn_menu.active .bar3 {
    transform: rotate(-30deg);
  }
  .btn_menu.active .menu {
    display: none;
  }
  .btn_menu.active .close {
    display: block;
  }
  /*  メニュー  */
  .head_nav {
    width: 90%;
    height: 100%;
    transition: all 0.2s;
    transform: translate(100%);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    background: #ffffff;
  }
  /*  メニューアクティブ時  */
  .head_nav.open {
    transform: translate(0);
  }
  /*　以下、個別にレイアウト調整　*/
  .nav {
    background: #ffffff;
  }
  .nav ul {
    padding: 100px 20px;
    box-sizing: border-box;
    text-align: center;
    background: #ffffff;
  }
  .nav li {
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
    border-right: 1px solid #ff7060;
    border-bottom: 1px solid #ff7060;
  }
  .nav li:nth-of-type(odd) {
    border-left: 1px solid #ff7060;
  }
  .nav li:nth-of-type(1), .nav li:nth-of-type(2) {
    border-top: 1px solid #ff7060;
  }
  .nav li img {
    height: 50px;
    width: auto;
  }
  .nav .panda {
    margin: 60px auto 0;
  }
  .spnav {
    padding: 15px 10px 20px;
    box-sizing: border-box;
    background: #fff;
    border-bottom: 1px solid #ff7060;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
  }
  .spnav .sitename {
    font-size: 10px;
    margin-bottom: 5px;
  }
  .spnav .logo {
    width: 200px;
  }
}

/*--------
--common parts
-----------------------------------------------*/
.contawrap {
  margin-top: 111px;
}

@media screen and (max-width: 1039px) {
  .contawrap {
    margin-top: 98px;
  }
}

@media screen and (max-width: 767px) {
  .contawrap {
    margin-top: 78px;
  }
}

.bnrarea {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}

.bnrarea:hover {
  opacity: 0.8;
}

a.btn01 {
  padding: 20px 40px;
  text-align: center;
  display: inline-block;
  color: #fff;
  background: #ff7060;
  transition: 0.2s;
  box-sizing: border-box;
  border: 1px solid #fff;
  text-decoration: none;
}

a.btn01:hover {
  background: #fff;
  border: 1px solid #ff7060;
  box-sizing: border-box;
  color: #ff7060;
}

.btnwrap {
  margin: 40px auto 0;
  text-align: center;
  width: 100%;
}

.ttl_01 {
  font-size: 30px;
  letter-spacing: 2px;
  color: #ff7060;
}

.ttl_01:after {
  width: 60px;
  height: 1px;
  background: #ff7060;
  content: "";
  display: block;
  margin: 40px 0;
}

@media screen and (max-width: 767px) {
  .ttl_01:after {
    margin: 20px 0;
  }
}

@media screen and (max-width: 767px) {
  .ttl_01 {
    font-size: 20px;
    line-height: 1;
  }
}

.ttl_02 {
  font-size: 30px;
  letter-spacing: 2px;
  color: #ff7060;
  text-align: center;
  line-height: 1;
  margin: 0 auto;
  width: 100%;
}

.ttl_02:after {
  width: 30px;
  height: 1px;
  background: #ff7060;
  content: "";
  display: block;
  margin: 30px auto;
}

@media screen and (max-width: 767px) {
  .ttl_02:after {
    margin: 20px auto;
  }
}

@media screen and (max-width: 767px) {
  .ttl_02 {
    font-size: 20px;
  }
}

.mv_p .catch {
  width: 40%;
  background: #ff7060;
  padding: 80px 0 100px 5%;
  box-sizing: border-box;
}

@media screen and (max-width: 1039px) {
  .mv_p .catch {
    width: 50%;
  }
}

.mv_p .eng_ttl {
  font-size: 75px;
  font-family: lato,sans-serif;
  font-style: italic;
  letter-spacing: 2px;
  color: #fff;
}

.mv_p .eng_ttl:after {
  width: 130px;
  height: 5px;
  background: #fff;
  content: "";
  display: block;
  margin: 20px 0;
}

@media screen and (max-width: 1039px) {
  .mv_p .eng_ttl {
    font-size: 7vw;
    line-height: 1.2;
  }
}

.mv_p .ja_ttl {
  font-size: 30px;
  color: #fff;
}

@media screen and (max-width: 1039px) {
  .mv_p .ja_ttl {
    font-size: 4vw;
  }
}

.mv_p .mv_bg {
  width: 60%;
  background: url(../img/mv_works.jpg) no-repeat left center;
  background-size: cover;
}

@media screen and (max-width: 1039px) {
  .mv_p .mv_bg {
    width: 50%;
    background: url(../img/mv_works.jpg) no-repeat left 10% center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .mv_p .mv_bg {
    background: url(../img/mv_works.jpg) no-repeat center center;
    background-size: cover;
  }
}

.sc01 .ttl {
  color: #df98ab;
  margin: 10% 0 20px -10px;
  font-size: 24px;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .sc01 .ttl {
    margin: 40px auto 20px;
    font-size: 20px;
  }
}

.sc01 .c_l {
  width: 40%;
}

@media screen and (max-width: 767px) {
  .sc01 .c_l {
    width: 90%;
  }
}

.sc01 .c_r {
  width: 55%;
}

@media screen and (max-width: 767px) {
  .sc01 .c_r {
    width: 100%;
  }
}

.sc01 .caution {
  width: 90%;
  max-width: 1040px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .sc01 .caution {
    width: 100%;
  }
}

.sc01 .caution .lead {
  color: #ff7060;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

.sc02 {
  background: url(../img/bg01.png) no-repeat right top 8%;
  background-size: 100px;
}

@media screen and (max-width: 767px) {
  .sc02 {
    background: url(../img/bg01.png) no-repeat right top 2%;
    background-size: 60px;
  }
}

.sc02 .lead {
  width: 100%;
  padding-right: 150px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .sc02 .lead {
    padding-right: 0px;
  }
}

.sc03 {
  background: url(../img/bg02.png) no-repeat right top 4%;
  background-size: 200px;
}

@media screen and (max-width: 767px) {
  .sc03 {
    background: url(../img/bg02.png) no-repeat right top 2%;
    background-size: 100px;
  }
}

.sc03 .lead {
  width: 100%;
  padding-right: 250px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .sc03 .lead {
    padding-right: 0px;
  }
}

.sc04 {
  background: url(../img/bg03.png) no-repeat right top 7%;
  background-size: 200px;
}

@media screen and (max-width: 767px) {
  .sc04 {
    background: url(../img/bg03.png) no-repeat right top 3%;
    background-size: 100px;
  }
}

.sc04 .lead {
  width: 100%;
  padding-right: 250px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .sc04 .lead {
    padding-right: 0px;
  }
}

.price_list {
  margin-top: 40px;
}

.price_list li {
  border: 1px solid #ff7060;
  margin-bottom: 40px;
  background: #fff;
}

.price_list li:last-of-type {
  margin-bottom: 0;
}

.price_list li .c_l {
  width: 30%;
  background: #ff7060;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 24px;
  letter-spacing: 2px;
  text-align: center;
}

@media screen and (max-width: 1039px) {
  .price_list li .c_l {
    width: 100%;
    padding: 20px 0;
  }
}

@media screen and (max-width: 767px) {
  .price_list li .c_l {
    font-size: 18px;
    padding: 15px 0;
  }
}

.price_list li .c_r {
  width: 70%;
  padding: 40px;
  box-sizing: border-box;
}

@media screen and (max-width: 1039px) {
  .price_list li .c_r {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .price_list li .c_r {
    padding: 20px 10px;
  }
}

.price_list li .ttl_min {
  font-size: 12px;
  display: block;
  margin-top: 20px;
}

.sc_exs {
  border: 1px dashed #ff7060;
}

.sc_exs .ttl {
  margin: -87px auto 40px;
  background: #fff;
  padding: 10px 60px;
  box-sizing: border-box;
  font-size: 24px;
  color: #ff7060;
  text-align: center;
  width: max-content;
}

@media screen and (max-width: 1039px) {
  .sc_exs .ttl {
    margin: -69px auto 16px;
  }
}

@media screen and (max-width: 767px) {
  .sc_exs .ttl {
    padding: 10px 20px;
    font-size: 18px;
    margin: -62px auto 10px;
  }
}

.sc_exs .textbox a {
  text-decoration: underline;
  color: #df98ab;
}

.sponserwrap .sc01 .list {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.sponserwrap .sc01 .list li {
  width: 32%;
  font-size: 16px;
  text-align: center;
}

.sponserwrap .sc01 .list li img {
  max-width: 250px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .sponserwrap .sc01 .list li {
    width: 90%;
    margin-bottom: 40px;
  }
  .sponserwrap .sc01 .list li:last-of-type {
    margin-bottom: 0;
  }
}

.sponserwrap .mv_bg {
  width: 60%;
  background: url(../img/mv_spo.jpg) no-repeat left top;
  background-size: cover;
}

@media screen and (max-width: 1039px) {
  .sponserwrap .mv_bg {
    width: 50%;
    background: url(../img/mv_spo.jpg) no-repeat left 10% top;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .sponserwrap .mv_bg {
    background: url(../img/mv_spo.jpg) no-repeat center top;
    background-size: cover;
  }
}

.contactwrap .mv_bg {
  width: 60%;
  background: url(../img/mv_contact.jpg) no-repeat top center;
  background-size: cover;
}

@media screen and (max-width: 1039px) {
  .contactwrap .mv_bg {
    width: 50%;
    background: url(../img/mv_contact.jpg) no-repeat top center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .contactwrap .mv_bg {
    background: url(../img/mv_contact.jpg) no-repeat top center;
    background-size: cover;
  }
}

.profilewrap .mv_bg {
  width: 60%;
  background: url(../img/mv_profile.jpg) no-repeat top center;
  background-size: cover;
}

@media screen and (max-width: 1039px) {
  .profilewrap .mv_bg {
    width: 50%;
    background: url(../img/mv_profile.jpg) no-repeat top center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .profilewrap .mv_bg {
    background: url(../img/mv_profile.jpg) no-repeat top center;
    background-size: cover;
  }
}

.profilewrap .flex_rv {
  align-items: center;
}

.profilewrap .sc01 .ttl span {
  font-size: 12px;
  padding-left: 15px;
  box-sizing: border-box;
}

@media screen and (max-width: 1039px) {
  .profilewrap .sc01 .ttl {
    margin: 40px auto 20px;
  }
}

.profilewrap .sc01 .c_l {
  width: 40%;
}

@media screen and (max-width: 1039px) {
  .profilewrap .sc01 .c_l {
    width: 90%;
    max-width: 450px;
    margin: 0 auto;
  }
}

.profilewrap .sc01 .c_r {
  width: 55%;
}

@media screen and (max-width: 1039px) {
  .profilewrap .sc01 .c_r {
    width: 100%;
  }
}

.profilewrap .sc02 {
  background: none;
}

.profilewrap .sc02 .c_l {
  width: 48%;
  background: url(../img/pro02.jpg) no-repeat right top;
  background-size: contain;
  text-shadow: 0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff;
}

@media screen and (max-width: 1039px) {
  .profilewrap .sc02 .c_l {
    width: 100%;
    margin-bottom: 40px;
  }
}

.profilewrap .sc02 .c_r {
  width: 48%;
  background: url(../img/pro03.jpg) no-repeat right top;
  background-size: contain;
  text-shadow: 0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff;
}

@media screen and (max-width: 1039px) {
  .profilewrap .sc02 .c_r {
    width: 100%;
  }
}

.profilewrap .sc03 {
  background: none;
}

.profilewrap .sc03 .c_l {
  width: 48%;
}

@media screen and (max-width: 1039px) {
  .profilewrap .sc03 .c_l {
    width: 100%;
    margin-bottom: 40px;
  }
}

.profilewrap .sc03 .c_r {
  width: 48%;
}

@media screen and (max-width: 1039px) {
  .profilewrap .sc03 .c_r {
    width: 100%;
  }
}

.profilewrap .sc04 {
  background: none;
}

.profilewrap .sc04 figure {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}

.profilewrap .sc05 {
  background: url(../img/pro04.jpg) no-repeat right top 100px;
  background-size: 260px;
  text-shadow: 0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff;
}

@media screen and (max-width: 1039px) {
  .profilewrap .sc05 {
    background: url(../img/pro04.jpg) no-repeat right top 100px;
    background-size: 200px;
  }
}

@media screen and (max-width: 767px) {
  .profilewrap .sc05 {
    background: none;
  }
}

.topwrap {
  /*.top_bgswipe{
    width: 80%;
    background-position:center left;
    margin-left: 20%;
  }
  .top_catch{
    width: 55%;
    height: 70vh;
    margin-left: -25%;
    min-width: 500px;
  }*/
}

@media screen and (max-width: 767px) {
  .topwrap .txt-c {
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .topwrap .sc01 .c_l {
    max-width: 300px;
  }
}

.topwrap .sc02 {
  background: none;
}

.topwrap .sc02 .article {
  width: 31%;
  margin-bottom: 40px;
}

@media screen and (max-width: 1039px) {
  .topwrap .sc02 .article {
    width: 48%;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 600px) {
  .topwrap .sc02 .article {
    width: 100%;
    margin-bottom: 40px;
  }
}

.topwrap .sc02 .blogdate {
  font-size: 12px;
  color: #666;
  margin: 15px 0 10px;
}

.topwrap .sc02 .blogtitle {
  font-size: 18px;
  margin-bottom: 10px;
  display: inline-block;
  color: #ff7060;
}

.topwrap .sc02 .blog-text {
  font-size: 14px;
  line-height: 2;
}

.topwrap .sc03 {
  background: none;
}

.topwrap .sc03 .date {
  font-size: 12px;
  color: #666;
  margin: 0 0 10px;
}

.topwrap .sc03 .blogtitle {
  font-size: 18px;
  margin-bottom: 10px;
  display: inline-block;
  color: #ff7060;
  text-decoration: underline;
}

.topwrap .sc03 .blogtitle a {
  color: #ff7060;
}

.topwrap .sc03 .blogtext {
  font-size: 14px;
  line-height: 2;
  border-bottom: 1px dotted #ff7060;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.topwrap .sc04 {
  background: none;
}

.topwrap .sc04 .list {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.topwrap .sc04 .list li {
  width: 32%;
  font-size: 16px;
  text-align: center;
}

.topwrap .sc04 .list li img {
  max-width: 250px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .topwrap .sc04 .list li {
    width: 90%;
    margin-bottom: 40px;
  }
  .topwrap .sc04 .list li:last-of-type {
    margin-bottom: 0;
  }
}

.topwrap .sc05 .worklist {
  max-width: 1000px;
  margin: 0 auto;
}

.topwrap .sc05 .worklist li {
  position: relative;
  margin-bottom: 60px;
}

.topwrap .sc05 .worklist li:last-of-type {
  margin-bottom: 0;
}

.topwrap .sc05 .worklist .c_l {
  width: 50%;
}

@media screen and (max-width: 767px) {
  .topwrap .sc05 .worklist .c_l {
    width: 100%;
    margin-bottom: 25px;
  }
}

.topwrap .sc05 .worklist .c_r {
  width: 45%;
}

@media screen and (max-width: 767px) {
  .topwrap .sc05 .worklist .c_r {
    width: 100%;
  }
}

.topwrap .sc05 .worklist .num {
  font-size: 75px;
  font-family: lato,sans-serif;
  font-style: italic;
  color: #ff7060;
  position: absolute;
  top: -60px;
  left: 5px;
  z-index: 2;
  font-weight: bold;
}

.topwrap .sc05 .worklist .ttl {
  color: #ff7060;
  font-size: 20px;
  border-left: 4px sollid #ff7060;
  margin-bottom: 20px;
}

.topwrap .mv_top {
  position: relative;
}

.topwrap .top_catch {
  position: absolute;
  top: 8%;
  left: 0px;
  z-index: 50;
  width: 42%;
  min-width: 500px;
}

@media screen and (max-width: 1039px) {
  .topwrap .top_catch {
    top: 3%;
  }
}

@media screen and (max-width: 767px) {
  .topwrap .top_catch {
    width: 80%;
    min-width: 280px;
    top: 12%;
  }
}

.topwrap .slide_top_wrap {
  width: 80%;
  margin-left: 20%;
}

/*--------
--blog
-----------------------------------------------*/
.col_left {
  width: 70%;
}

@media screen and (max-width: 767px) {
  .col_left {
    width: 100%;
    margin-bottom: 60px;
  }
}

.col_right {
  width: 25%;
}

@media screen and (max-width: 767px) {
  .col_right {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
}

.blogwrap .mv_bg {
  width: 60%;
  background: url(../img/mv_blog.jpg) no-repeat top center;
  background-size: cover;
}

@media screen and (max-width: 1039px) {
  .blogwrap .mv_bg {
    width: 50%;
    background: url(../img/mv_blog.jpg) no-repeat top center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .blogwrap .mv_bg {
    background: url(../img/mv_blog.jpg) no-repeat top center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .related-article li {
    margin-bottom: 40px;
  }
}

.related-article-title {
  font-size: 16px;
  color: #ff7060;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.article_blog_rela .eye-catch {
  width: 30%;
}

@media screen and (max-width: 767px) {
  .article_blog_rela .eye-catch {
    width: 90%;
    margin: 0 auto 20px !important;
  }
}

.article_blog_rela .related-article-text {
  width: 65%;
}

@media screen and (max-width: 767px) {
  .article_blog_rela .related-article-text {
    width: 100%;
  }
}

.article_blog_rela .related-article-text .date {
  font-size: 12px;
  text-align: right;
  display: inline-block;
}

.article_blog_rela .related-article-text .ttl {
  font-size: 16px;
}

.article_blog {
  margin-bottom: 40px;
  padding: 0 0 40px;
  border-bottom: 1px dashed #eee;
}

.article_blog .eye-catch {
  width: 30%;
}

@media screen and (max-width: 767px) {
  .article_blog .eye-catch {
    width: 90%;
    margin: 0 auto 20px;
  }
}

.article_blog .article-right {
  width: 65%;
}

@media screen and (max-width: 767px) {
  .article_blog .article-right {
    width: 100%;
  }
}

.article_blog .article-right .cate-name {
  border: 1px solid #d6d6d6;
  padding: 5px 10px 5px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 12px;
  margin-right: 10px;
}

.article_blog .article-right .cate-name a {
  text-decoration: none;
  color: #1b1b1b;
}

.article_blog .article-right .date {
  font-size: 12px;
  text-align: right;
  display: inline-block;
}

.article_blog .article-right .title {
  font-size: 18px;
  margin: 20px 0;
}

@media screen and (max-width: 767px) {
  .article_blog .article-right .title {
    font-size: 16px;
  }
}

.article_blog .article-right .title a {
  text-decoration: none;
  transition: 0.8s;
}

.article_blog .article-right .title a:hover {
  color: #ff7060;
}

.article_blog .article-right .text {
  font-size: 14px;
}

.article_info {
  margin-bottom: 40px;
  padding: 0 0 40px;
  border-bottom: 1px dashed #eee;
}

.article_info .cate-name {
  border: 1px solid #d6d6d6;
  padding: 5px 10px 5px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 12px;
  margin-right: 10px;
}

.article_info .cate-name a {
  text-decoration: none;
  color: #1b1b1b;
}

.article_info .cate-tag {
  padding: 5px 10px 5px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 12px;
  margin-right: 10px;
}

.article_info .cate-tag a {
  text-decoration: none;
  color: #1b1b1b;
}

.article_info .date {
  font-size: 12px;
  text-align: right;
  display: inline-block;
}

.article_info .title {
  font-size: 18px;
  margin: 20px 0 20px;
  border-bottom: 1px solid #ff7060;
}

@media screen and (max-width: 767px) {
  .article_info .title {
    font-size: 16px;
  }
}

.article_info .title a {
  text-decoration: none;
  transition: 0.8s;
}

.article_info .title a:hover {
  color: #ff7060;
}

.article_info .more {
  border: 1px solid #ff7060;
  margin: 10px 0 0;
  text-align: center;
  padding: 3px 15px;
  box-sizing: border-box;
  display: inline-block;
}

.article_info .more a {
  color: #ff7060;
  font-size: 12px;
}

.article_info .text {
  font-size: 14px;
}

.article_info .blog-title {
  font-size: 18px;
  margin: 10px 0 20px;
  border-bottom: 1px solid #ff7060;
  padding-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .article_info .blog-title {
    font-size: 16px;
  }
}

.sidebar-block {
  margin-bottom: 50px;
}

.sidebar-block .ttl {
  font-size: 16px;
  color: #ff7060;
  margin-bottom: 20px;
  letter-spacing: 2px;
  padding: 0 0 5px;
  border-bottom: 1px dashed #ddd;
}

.sidebar-block .cat-item {
  margin-bottom: 10px;
  font-size: 14px;
  transition: 1s;
}

.sidebar-block .cat-item a:hover {
  color: #ff7060;
}

.sidebar-block.recommend li {
  margin-bottom: 20px;
}

.sidebar-block.recommend .eye-catch {
  width: 30%;
  margin: 0 auto 20px !important;
}

.sidebar-block.recommend .sidebar-text {
  width: 65%;
  font-size: 12px;
}

.sidebar-block.ranking li {
  margin-bottom: 20px;
}

.sidebar-block.ranking .eye-catch {
  width: 30%;
  margin: 0 auto 20px !important;
}

.sidebar-block.ranking .sidebar-text {
  width: 65%;
  font-size: 12px;
}

.pagenavi_o {
  text-align: center;
  margin: 0 auto 40px;
}

.pagenavi_o .wp-pagenavi a:hover, .pagenavi_o .wp-pagenavi span.current {
  border-color: #ff7060;
  color: #ff7060;
  font-weight: normal;
}

.infowrap .mv_bg {
  width: 60%;
  background: url(../img/mv_info.jpg) no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 1039px) {
  .infowrap .mv_bg {
    width: 50%;
    background: url(../img/mv_info.jpg) no-repeat center center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .infowrap .mv_bg {
    background: url(../img/mv_info.jpg) no-repeat center center;
    background-size: cover;
  }
}

.infowrap .text img {
  width: 90%;
  margin: 0 auto 40px;
  max-width: 800px;
}

.infowrap .text h2 {
  font-weight: bold;
  color: #ff7060;
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #ff7060;
}

.infowrap .text h3 {
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.infowrap .text h4 {
  color: #ff7060;
  margin-top: 10px;
  margin-bottom: 10px;
}

.infowrap .text a {
  text-decoration: underline;
}

.infowrap .pb-20 {
  padding-bottom: 20px;
  line-height: normal;
}

.infowrap .pb-20 img {
  width: 90%;
  margin: 0 auto 0px;
  max-width: 800px;
}

.infowrap font {
  text-align: center;
}

.infowrap .eye-catch {
  max-width: 800px;
  margin: 0 auto 40px;
}

div.wp_social_bookmarking_light {
  margin-top: 40px !important;
}

.info-left-wrapper a {
  font-size: 14px;
  color: #ff7060;
}

.info-left-wrapper .before {
  display: inline-block;
  padding-right: 10px;
}

.info-left-wrapper .list {
  display: inline-block;
  padding: 0 10px;
}

.info-left-wrapper .next {
  display: inline-block;
}

/*-----------------------------------------------
--pager
-----------------------------------------------*/
.pagination {
  text-align: center;
  margin-bottom: 15px;
}

.pagination span,
.pagination a {
  display: inline-block;
}

a.page-numbers,
.pagination .current {
  padding: 5px 8px;
  margin: 0 2px;
  text-decoration: none;
}

.pagination .current {
  opacity: 0.8;
}

/*-----------------------------------------------
--accordion
-----------------------------------------------*/
.displayNone {
  display: none;
}

.accordion li {
  padding: 10px;
}

.accordion a {
  display: block;
}

.contentWrap {
  margin: 10px;
  padding: 20px 0;
}

.contentWrap ul {
  margin-bottom: 20px;
}

.switch {
  cursor: pointer;
  font-weight: bold;
  padding: 10px 40px 10px 10px;
  font-size: 14px;
  background: #eee;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.switch:after {
  position: absolute;
  top: 60%;
  right: 10px;
  margin-top: -18px;
  content: '>';
  font-size: 18px;
  font-weight: bold;
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -moz-transition: all, 0.25s, linear;
  -o-transition: all, 0.25s, linear;
  -webkit-transition: all, 0.25s, linear;
  transition: all, 0.25s, linear;
  font-family: sans-serif;
  color: #FF6685;
}

.switch.open:after {
  -moz-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-family: sans-serif;
}

/*-----------------------------------------------
--footer
-----------------------------------------------*/
.footer {
  border-top: 1px solid #ff7060;
  position: relative;
  margin-top: 150px;
  /*.running{
    width: 40px;
    position: absolute;
    top: -76px;
    left: 5%;
  }*/
}

.footer .running {
  width: 200px;
  position: absolute;
  top: -100px;
  left: 0%;
}

@media screen and (max-width: 767px) {
  .footer .running {
    left: -9%;
  }
}

.footer .logo {
  width: 70%;
  max-width: 300px;
  margin: 20px auto 40px;
}

@media screen and (max-width: 767px) {
  .footer .logo {
    margin: 20px auto 20px;
  }
}

.footer .sns {
  margin: 0 auto 20px;
  width: 150px;
}

@media screen and (max-width: 767px) {
  .footer .sns {
    margin: 0 auto;
  }
}

.footer .sns li {
  float: left;
  margin-right: 20px;
  font-size: 40px;
}

.footer .sns li:last-of-type {
  margin-right: 0px;
}

.footer .sns li a {
  transition: 0.2s;
}

.footer .sns li:hover a {
  color: #ff7060;
}

.footer .subnav {
  text-align: center;
  width: 700px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .footer .subnav {
    display: none;
  }
}

.footer .subnav li {
  float: left;
  border-left: 1px solid #1b1b1b;
  padding: 0px 20px;
  box-sizing: border-box;
  line-height: 1;
  font-size: 14px;
}

.footer .subnav li:last-of-type {
  border-right: 1px solid #1b1b1b;
}

.footer .subnav li a {
  transition: 0.2s;
}

.footer .subnav li:hover a {
  color: #ff7060;
}

.footer .copy {
  font-size: 10px;
  text-align: center;
  padding: 20px 0;
  background: #eeeeee;
}

.ebi-1gou {
  animation: ebi-1gou-move 10s ease-in-out 0s infinite normal;
}

@keyframes ebi-1gou-move {
  0% {
    transform: translateX(-5vw);
  }
  100% {
    transform: translateX(102vw);
  }
}

/*-----------------------------------------------
--contact
-----------------------------------------------*/
.contact {
  background: #efefef;
  padding: 60px;
  box-sizing: border-box;
  max-width: 900px;
  margin: 0 auto;
}

.contact h2 {
  margin-bottom: 40px;
  text-align: center;
}

.contact .des {
  margin-bottom: 20px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .contact .des {
    text-align: left;
  }
}

.contact .cont_wrap {
  font-size: 14px;
}

.contact .cont_wrap .ttl {
  width: 100%;
  padding: 20px 0 10px;
  font-weight: bold;
}

.contact .cont_wrap dl {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  -webkit-flex-direction: row;
  /* Safari */
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  /* Safari */
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  /* Safari */
  justify-content: space-between;
  align-items: stretch;
}

.contact .cont_wrap dt {
  width: 30%;
  padding: 20px 10px;
  box-sizing: border-box;
}

.contact .cont_wrap dd {
  width: 70%;
  padding: 20px 10px;
  box-sizing: border-box;
}

.contact .cont_wrap dt span {
  color: #ff0000;
  padding-left: 10px;
  font-size: 12px;
  box-sizing: border-box;
}

.contact .cont_wrap input[type="text"] {
  width: 100%;
  max-width: 500px;
  padding: 10px 10px;
  box-sizing: border-box;
  border: 1px solid #bbb;
}

.contact .cont_wrap textarea {
  width: 100%;
  max-width: 500px;
  padding: 10px 10px;
  box-sizing: border-box;
  border: 1px solid #bbb;
}

.contact .cont_wrap input[type="tel"] {
  width: 100%;
  max-width: 300px;
  padding: 10px 10px;
  box-sizing: border-box;
  border: 1px solid #bbb;
}

.contact .cont_wrap input[type="email"] {
  width: 100%;
  max-width: 300px;
  padding: 10px 10px;
  box-sizing: border-box;
  border: 1px solid #bbb;
}

.contact .cont_wrap .wpcf7-list-item {
  margin-left: 20px;
}

.contact .cont_wrap .wpcf7-list-item.first {
  margin-left: 0px;
}

.contact .cont_wrap .p_pri {
  box-sizing: border-box;
  border: 1px solid #aaa;
  padding: 15px;
  text-align: center;
}

.contact .cont_wrap .p_pri .brsp {
  display: none;
}

.contact .cont_wrap .p_pri .p_pri_btn {
  margin: 10px auto 0;
}

.contact .cont_wrap .submit {
  text-align: center;
}

.contact .cont_wrap .submit input {
  background: none;
  border: none;
  color: #fff;
  font-family: 'Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','ヒラギノ明朝 ProN','Hiragino Mincho ProN',"Sawarabi Mincho",serif;
  font-size: 16px;
  box-sizing: border-box;
  width: 200px;
  height: 60px;
  background: #9c9c9c;
  color: #fff;
  line-height: 60px;
  margin: 40px auto 0;
  cursor: pointer;
  text-align: center;
}

.contact .cont_wrap .submit:hover {
  opacity: 0.8;
}

@media screen and (max-width: 767px) {
  .contact {
    padding: 40px 20px;
  }
  .contact .cont_wrap dt {
    width: 100%;
    padding: 20px 10px 10px;
    box-sizing: border-box;
  }
  .contact .cont_wrap dd {
    width: 100%;
    padding: 0px 10px 20px;
    box-sizing: border-box;
    border-top: none;
  }
  .contact .cont_wrap .f_l {
    border-bottom: none;
  }
  .contact .cont_wrap .p_pri {
    box-sizing: border-box;
    padding: 15px;
    text-align: center;
  }
  .contact .cont_wrap .p_pri .brsp {
    display: block;
  }
  .contact .cont_wrap .submit {
    margin: 20px auto 0;
  }
}
