@charset "utf-8";
@import "../core/_mixin.scss";
@import "../core/_config.scss";

/*-----------------------------------------------
--メインカラム
-----------------------------------------------*/

.lay_l{
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  text-align: left;
  @include media(767px) {
    width: 95%;
  }
}
.lay_m{
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  text-align: left;
  @include media(767px) {
    width: 95%;
  }
}
.lay_s{
  max-width: 1040px;
  width: 90%;
  margin: 0 auto;
  text-align: left;
  @include media(767px) {
    width: 95%;
  }
}

/*-----------------------------------------------
--コンテンツボックス 都度調整OK
-----------------------------------------------*/

.pt_xl{
  padding-top:100px;
  @include media(767px) {
    padding-top:80px;
  }
}
.pt_l{
  padding-top:60px;
  @include media(767px) {
    padding-top:40px;
  }
}
.pt_m{
  padding-top:60px;
  @include media(767px) {
    padding-top:40px;
  }
}
.pt_s{
  padding-top:40px;
  @include media(767px) {
    padding-top:20px;
  }
}
.pb_xl{
  padding-bottom:100px;
  @include media(767px) {
    padding-bottom:80px;
  }
}
.pb_l{
  padding-bottom:100px;
  @include media(767px) {
    padding-bottom:80px;
  }
}
.pb_m{
  padding-bottom:60px;
  @include media(767px) {
    padding-bottom:40px;
  }
}
.pb_s{
  padding-bottom:40px;
  @include media(767px) {
    padding-bottom:20px;
  }
}
.ptb_xl{
  padding:100px 0;
  @include media(767px) {
    padding:80px 0;
  }
}
.ptb_l{
  padding:60px 0;
  @include media(767px) {
    padding:40px 0;
  }
}
.ptb_m{
  padding:40px 0;
  @include media(767px) {
    padding:20px 0;
  }
}
.ptb_s{
  padding:40px 0;
  @include media(767px) {
    padding:20px 0;
  }
}
.pa_xl{
  padding:100px;
  box-sizing: border-box;
  @include media(767px) {
    padding:80px;
  }
}
.pa_l{
  padding:60px;
  box-sizing: border-box;
  @include media(767px) {
    padding:40px 10px;
  }
}
.pa_m{
  padding:60px;
  box-sizing: border-box;
  @include media(1039px) {
    padding:40px;
  }
  @include media(767px) {
    padding:40px 10px;
  }
}
.pa_s{
  padding:40px;
  box-sizing: border-box;
  @include media(767px) {
    padding:20px 10px;
  }
}
.mt_xl{
  margin-top:100px;
  @include media(767px) {
    margin-top:80px;
  }
}
.mt_l{
  margin-top:140px;
  @include media(767px) {
    margin-top:80px;
  }
}
.mt_m{
  margin-top:60px;
  @include media(767px) {
    margin-top:40px;
  }
}
.mt_s{
  margin-top:40px;
  @include media(767px) {
    margin-top:20px;
  }
}
.mb_xl{
  margin-bottom:100px;
  @include media(767px) {
    margin-bottom:80px;
  }
}
.mb_l{
  margin-bottom:60px;
  @include media(767px) {
    margin-bottom:40px;
  }
}
.mb_m{
  margin-bottom:60px;
  @include media(767px) {
    margin-bottom:40px;
  }
}
.mb_s{
  margin-bottom:40px;
  @include media(767px) {
    margin-bottom:20px;
  }
}
.ma_xl{
  margin:100px auto;
  @include media(767px) {
    margin:80px auto;
  }
}
.ma_l{
  margin:80px auto;
  @include media(767px) {
    margin:60px auto;
  }
}
.ma_m{
  margin:60px auto;
  @include media(767px) {
    margin:40px auto;
  }
}
.ma_s{
  margin:40px auto;
  @include media(767px) {
    margin:20px auto;
  }
}
/*-----------------------------------------------
--フォントサイズ 都度調整OK
-----------------------------------------------*/

.fz_l{
  font-size:24px;
  @include media(767px) {
    font-size:20px;
  }
}
.fz_m{
  font-size:20px;
  @include media(767px) {
    font-size:18px;
  }
}
.fz_s{
  font-size:12px;
  @include media(767px) {
    font-size:10px;
  }
}

/*-----------------------------------------------
--段落用（レスポンシブ）
-----------------------------------------------*/

.pc{
  display: block;
  @include media(767px) {display: none;}
}
.tab{
  display: none;
  @include media(1040px) {display: block;}
  @include media(767px) {display: none;}
}
.sp{
  display: none;
  @include media(767px) {display: block;}
}
.spmin{
  display: none;
  @include media(450px) {display: block;}
}

/*-----------------------------------------------
--ホバーアクション（透過）
-----------------------------------------------*/

.hv{
  transition: 0.2s;
}
.hv:hover{
  opacity: 0.8;
}


/*-----------------------------------------------
--画面ローディング
-----------------------------------------------*/


body {
  animation: fadeIn 2s ease 0s 1 normal;
  -webkit-animation: fadeIn 2s ease 0s 1 normal;
}

@keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}

/*-----------------------------------------------
--フロート 
-----------------------------------------------*/

.clearfix:after{
  content: "."; 
  display: block; 
  height: 0; 
  font-size:0;	
  clear: both; 
  visibility:hidden;
}

/*-----------------------------------------------
--grid
-----------------------------------------------*/


.grid_c3{
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  .grid_c3_box{
    
  }
}

.grid_autofit{
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  .grid_c3_box{

  }
}

/*-----------------------------------------------
--Flex
-----------------------------------------------*/

.flex {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:stretch;
  @include media(767px) {
    justify-content:space-around;
  }
}
.flex_rv{
  display:flex;
  flex-direction: row-reverse;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:stretch;
  @include media(767px) {
    justify-content:space-around;
  }
}
.flex_ct {
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items: center;
  @include media(767px) {
    justify-content:space-around;
  }
}

/*-----------------------------------------------
--Fonts
-----------------------------------------------*/

body {
  line-height: 1.6;
  font-size: 16px;
  @include media(767px) {
    font-size: 14px;
  }
  word-wrap : break-word;
  overflow-wrap : break-word;/*折り返しを綺麗にする*/
  font-weight: normal;
  font-family:Avenir, Verdana, Arial, 'Noto Sans JP', "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Roboto, Meiryo, sans-serif;
  text-rendering: optimizeLegibility;/*アンチエイリアスなどフォント見やすくする*/
  color: $base_font_color;
  text-align: justify;/*均等に配置する*/
}

/*----游ゴシック__fontfamily---*/
/*body {
font-family:Avenir, Verdana, Arial, "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Roboto, Meiryo, sans-serif;
font-weight: 500;
}*/

a, a:link, a:active, a:visited {text-decoration: none;outline: none;color:$base_font_color;}
a:hover, a:active {outline: 0;}

/*-----------------------------------------------
--fontfamily
-----------------------------------------------*/
.serif {
  font-family: 'Times New Roman', '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', '小塚明朝 ProN', 'メイリオ', Meiryo, serif;
}

/*-----------------------------------------------
--パーツ各種
-----------------------------------------------*/
.textbox{
  line-height: 2.2;
  p{
    margin-bottom: 25px;
    &:last-of-type{
      margin-bottom: 0;
    }
  }
  a{
    text-decoration: underline;
    display: inline-block;
    transition: 0.2s;
    &:hover{
      opacity: 0.8;
      color:  $c1;
    }
  }
  strong{
    font-weight: bold;
  }
  strong.deco{
    color:$c1;
    background: linear-gradient(transparent 60%, #ffff66 60%);
  }
}
.tri{
  width: 0;
  height: 0;
  border: 80px solid transparent;
  border-top: 60px solid #fe6601;
  display: block;
  margin: 0 auto;
  @include media(767px) {
    border: 60px solid transparent;
    border-top: 40px solid #fe6601;
  }
}
.bold{
  font-weight: bold;
}
.hl01{
  color:$c1;
}
.txt-c{
  text-align: center;
}
.txt-r{
  text-align: right;
}
.txt-l{
  text-align: left;
}
.grad-btn {
  background:$c1;
  padding:10px 20px;
  box-sizing: border-box;
  text-align: center;
  color:#fff;
  font-size:14px;
  transition: 0.2s;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  &:hover{
    opacity: 0.8;
  }
  &:before {
    content: "続きを読む";
  }
}

.grad-item {
  position: relative;
  overflow: hidden;
  height: 0px; /*隠した状態の高さ*/
}
.grad-item::before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px; /*グラデーションで隠す高さ*/
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,0.9) 50%, #fff 100%);
  background: linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,0.9) 50%, #fff 100%);
  content: "";
}
.grad-trigger {
  display: none; /*チェックボックスは常に非表示*/
}
.grad-trigger:checked ~ .grad-btn::before {
  content: "閉じる" /*チェックされていたら、文言を変更する*/
}
.grad-trigger:checked ~ .grad-item {
  height: auto; /*チェックされていたら、高さを戻す*/
}
.grad-trigger:checked ~ .grad-item::before {
  display: none; /*チェックされていたら、grad-itemのbeforeを非表示にする*/
}

/*-----------------------------------------------
--Animation
-----------------------------------------------*/

.ani01_wrap {
  width: 0;
  overflow: hidden;
  transition: 1000ms linear;
}
.ani01_wrap.is-show {
  width: 320px;
}