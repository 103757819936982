@charset "utf-8";
@import "core/_mixin.scss";
@import "base/_base_setting";

/*-----------------------------------------------
--header
-----------------------------------------------*/
@import "parts/_header.scss";


@import "page/_page.scss";

/*-----------------------------------------------
--pager
-----------------------------------------------*/
@import "parts/_pager.scss";

/*-----------------------------------------------
--accordion
-----------------------------------------------*/
@import "parts/_accordion.scss";

/*-----------------------------------------------
--footer
-----------------------------------------------*/
@import "parts/_footer.scss";

/*-----------------------------------------------
--contact
-----------------------------------------------*/
@import "parts/_contact.scss";
